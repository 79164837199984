import { CommonModule, Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
  logoutAction,
  switchUserAssociatedFiscalYearAction,
} from '../../../authentication/store/actions/authentication.action';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { StopReportDialogComponent } from '../../../taxation/components/dialogs/stop-report-dialog/stop-report-dialog.component';
import { SwitchFiscalYearDialogComponent } from '../../../taxation/components/dialogs/switch-fiscal-year-dialog/switch-fiscal-year-dialog.component';
import { UpgradeFishingDialogComponent } from '../../../taxation/components/dialogs/upgrade-fishing-dialog/upgrade-fishing-dialog.component';
import { Assessment, AssessmentStatus } from '../../../taxation/models/assessment.model';
import { AvailableAddons, FiscalYearPlanEstimate, PaymentEstimateV3 } from '../../../taxation/models/payment.model';
import { UserAccount } from '../../../taxation/models/user-account.model';
import { User, UserAddon } from '../../../taxation/models/user.model';
import {
  toggleAutomaticAssessmentAction,
  unsyncAssessmentAction,
} from '../../../taxation/store/actions/assessment.action';
import { loadAvailableAddonsAction } from '../../../taxation/store/actions/payment.action';
import * as fromAssessment from '../../../taxation/store/selectors/assessment.selector';
import * as fromPayment from '../../../taxation/store/selectors/payment.selector';
import { Feature } from '../../models/feature.model';
import { Link } from '../../models/link.model';
import { UserPreferences } from '../../models/user-preferences.model';
import { FeatureService } from '../../services/feature.service';
import { UtilsService } from '../../services/utils.service';
import { goToAction, pingOutUserAction, pushTagAction, trackEventAction } from '../../store/actions/shared.action';
import { AssessmentStatusComponent } from '../assessment-status/assessment-status.component';
import { WelcomeDialogComponent } from '../dialogs/welcome-dialog/welcome-dialog.component';
import { NavigationTileComponent } from '../navigation-tile/navigation-tile.component';
import { PlanStatusComponent } from '../plan-status/plan-status.component';
import { ProfileSidenavComponent } from '../profile-sidenav/profile-sidenav.component';
import { AccountsDialogComponent } from '../../../taxation/components/dialogs/accounts-dialog/accounts-dialog.component';
import { loadUserAccountsAction } from '../../../taxation/store/actions/account.action';
import * as fromAccount from '../../../taxation/store/selectors/account.selector';

@Component({
  selector: `app-sidenav`,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatDividerModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    AssessmentStatusComponent,
    NavigationTileComponent,
    ProfileSidenavComponent,
    PlanStatusComponent,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: `./sidenav.component.html`,
  styleUrls: [`./sidenav.component.scss`],
})
export class SidenavComponent implements OnInit, OnDestroy {
  currentYear = moment().year();
  walletManagerLinks: Link[] = [
    {
      label: `MENU.ACCOUNTS`,
      routeIcon: `/assets/img/icons/wallet.svg`,
      routeActiveIcon: `/assets/img/icons/wallet-active.svg`,
      route: `/accounts`,
    },
    {
      label: `MENU.PORTFOLIO`,
      routeIcon: `/assets/img/icons/dashboard.svg`,
      routeActiveIcon: `/assets/img/icons/dashboard-active.svg`,
      route: `/dashboard`,
    },
    {
      label: `MENU.OPPORTUNITIES`,
      routeIcon: `/assets/img/icons/opportunities.svg`,
      routeActiveIcon: `/assets/img/icons/opportunities-active.svg`,
      route: `/opportunities`,
    },
  ];
  taxationLinks: Link[] = [
    {
      label: `MENU.TAX_REPORT`,
      routeIcon: `/assets/img/icons/document.svg`,
      routeActiveIcon: `/assets/img/icons/document-active.svg`,
      route: `/report/${this.currentYear}`,
    },
    {
      label: `MENU.TRANSACTIONS`,
      routeIcon: `/assets/img/icons/transactions.svg`,
      routeActiveIcon: `/assets/img/icons/transactions-active.svg`,
      route: `/transactions`,
    },
  ];

  assessment: Assessment;
  user: User;
  userPreferences: UserPreferences;
  language: string;
  associatedFiscalYear: number;
  availableAddons: AvailableAddons;
  hasPaid = true;

  userAccounts: UserAccount[];
  upgradeFishingEstimate: PaymentEstimateV3;
  assessmentStatus: AssessmentStatus;
  plansByFiscalYears: Map<string, FiscalYearPlanEstimate>;
  assessmentETA = 0;

  showNextFiscalYearUpgrade = false;
  moveToFiscalYearLabel = ``;
  currentUrl: string;

  private readonly destroy$: Subject<void> = new Subject<void>();
  accountsDialogOpened = false;
  urlAccount: string;

  constructor(
    private readonly assessmentStore$: Store<fromAssessment.State>,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly paymentStore$: Store<fromPayment.State>,
    public readonly utilsService: UtilsService,
    private readonly featureService: FeatureService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly accountStore$: Store<fromAccount.State>,
  ) {}

  ngOnInit(): void {
    this.currentUrl = this.router.url;

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        map((event: any) => {
          if (event.url) {
            this.currentUrl = event.url;
          }
        }),
      )
      .subscribe();

    this.assessmentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAssessment.selectAssessmentStatus),
        map((assessmentStatus: AssessmentStatus) => (this.assessmentStatus = assessmentStatus)),
      )
      .subscribe();

    this.assessmentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAssessment.selectAssessment),
        map((assessment: Assessment) => {
          this.assessment = assessment;
        }),
      )
      .subscribe();

    this.authStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAuth.selectUser),
        map((user: User) => {
          this.user = user;

          if (this.user) {
            mixpanel.identify(this.user.id);
            this.associatedFiscalYear = user.associatedFiscalYear;

            this.taxationLinks[0].route = `/report/${this.associatedFiscalYear}`;

            this.showNextFiscalYearUpgrade =
              this.utilsService.isFiscalYearSwitchable(this.currentYear) &&
              this.currentYear !== this.associatedFiscalYear;

            if (this.showNextFiscalYearUpgrade) {
              this.moveToFiscalYearLabel = this.translateService.instant(`MOVE_TO_YEAR`, {
                year: this.associatedFiscalYear + 1,
              });

              const switchFiscalYearDialog = localStorage.getItem(`switch-fiscal-year-dialog`);
              if (!switchFiscalYearDialog) {
                this.openSwitchFiscalYearDialog();
              }
            }

            this.checkDashboardFeature();
            this.checkOpportunitiesFeature();
          } else {
            if (!this.utilsService.isFiscalYearSwitchable(this.currentYear)) {
              this.currentYear -= 1;
            }

            this.taxationLinks[0].route = `/report/${this.currentYear}`;
          }
        }),
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectUserPreferences),
        map((userPreferences: UserPreferences) => {
          this.userPreferences = userPreferences;
        }),
      )
      .subscribe();

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectUpgradeFishingEstimate),
        map((upgradeFishingEstimate: PaymentEstimateV3) => {
          this.upgradeFishingEstimate = upgradeFishingEstimate;

          if (this.upgradeFishingEstimate) {
            this.hasPaid =
              this.upgradeFishingEstimate.due === 0 ||
              this.utilsService.isRecommendedPlanOverkilled(this.upgradeFishingEstimate);
          }
        }),
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectLanguage),
        map((language: string) => (this.language = language)),
      )
      .subscribe();

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectAvailableAddons),
        map((availableAddons: AvailableAddons) => {
          this.availableAddons = availableAddons;

          if (!this.availableAddons) {
            this.paymentStore$.dispatch(loadAvailableAddonsAction());
          }
        }),
      )
      .subscribe();

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectPlansByFiscalYears),
        map((plansByFiscalYears: Map<string, FiscalYearPlanEstimate>) => {
          if (plansByFiscalYears) {
            this.plansByFiscalYears = plansByFiscalYears;
          }
        }),
      )
      .subscribe();

    this.assessmentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAssessment.selectAssessmentETA),
        map((assessmentETA: number) => {
          this.assessmentETA = assessmentETA;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(): void {
    this.utilsService.clearPWACache();
    this.sharedStore$.dispatch(pingOutUserAction());
    this.assessmentStore$.dispatch(unsyncAssessmentAction());
    this.assessmentStore$.dispatch(logoutAction());
  }

  upgradePlan(name?: string): void {
    this.utilsService.openDialog(UpgradeFishingDialogComponent, `400px`, `auto`, {
      name: name || `upgrade-nav-bar`,
      checkoutType: `PLAN`,
      offer: this.upgradeFishingEstimate.suggestedPlan,
      recommendedPlan: this.upgradeFishingEstimate.selectedPlan,
      fiscalYear: this.associatedFiscalYear,
    });
  }

  navigateTo(link: Link): void {
    this.sharedStore$.dispatch(goToAction({ url: link.route }));
  }

  assessmentStatusClick(): void {
    if (!this.user.automaticAssessmentEnabled) {
      this.openStopReportDialog();
    } else {
      switch (this.assessmentStatus) {
        case `NEED_PLAN_UPGRADE`:
          this.upgradePlan(`upgrade-assessement-status`);
          break;
        case `NEED_ACCOUNT_ADD`:
          this.openAccountsDialog();
          break;
        case `IN_PROGRESS`:
          this.openStopReportDialog();
      }
    }
  }

  openStopReportDialog(): void {
    const dialogRef = this.utilsService.openDialog(StopReportDialogComponent, `600px`, `auto`, {
      automaticAssessmentEnabled: this.user.automaticAssessmentEnabled,
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        tap((res: boolean) => {
          if (res) {
            this.assessmentStore$.dispatch(toggleAutomaticAssessmentAction());
          }
        }),
      )
      .subscribe();
  }

  upgradeAddon(): void {
    const offer: UserAddon = this.availableAddons.AOCARE001 && this.availableAddons.AOCARE002 ? null : `AOCARE002`;

    this.utilsService.openDialog(UpgradeFishingDialogComponent, `400px`, `auto`, {
      name: `upgrade-nav-bar-addons`,
      checkoutType: `ADDON`,
      offer,
    });
  }

  bookCall(): void {
    let url = ``;
    switch (this.userPreferences?.language) {
      case `fr`:
        url = `https://meet.brevo.com/waltio/full-service`;
        break;
      case `en`:
        url = `https://meet.brevo.com/waltio/full-service-en`;
        break;
      case `es`:
        url = `https://meet.brevo.com/waltio/full-service-es`;
        break;
    }

    this.sharedStore$.dispatch(trackEventAction({ event: `call_full_service_profile` }));

    window.open(url, `_blank`);
  }

  openWelcomeDialog(): void {
    const dialogRef = this.utilsService.openDialog(WelcomeDialogComponent, `auto`, `auto`, {
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          localStorage.setItem(`welcome-dialog`, `true`);
        }),
      )
      .subscribe();
  }

  checkDashboardFeature(): void {
    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectFeatures(`dashboard`)),
        map((features: Feature[]) => {
          if (features) {
            this.featureService
              .isCustomersListFeatureEnabled(features[0], this.user.email)
              .pipe(
                map((res: boolean) => {
                  if (res && !localStorage.getItem(`welcome-dialog`) && !environment.production) {
                    this.openWelcomeDialog();
                  }
                }),
              )
              .subscribe();
          }
        }),
      )
      .subscribe();
  }

  checkOpportunitiesFeature(): void {
    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectFeatures(`opportunities`)),
        map((features: Feature[]) => {
          if (features) {
            this.featureService
              .isCustomersListFeatureEnabled(features[0], this.user.email)
              .pipe(
                map((res: boolean) => {
                  if (res && !localStorage.getItem(`welcome-dialog`) && !environment.production) {
                    this.openWelcomeDialog();
                  }
                }),
              )
              .subscribe();
          }
        }),
      )
      .subscribe();
  }

  openSwitchFiscalYearDialog(): void {
    const dialogRef = this.utilsService.openDialog(SwitchFiscalYearDialogComponent, `523px`, `auto`, {
      fiscalYear: this.associatedFiscalYear + 1,
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        tap((res: boolean) => {
          if (res) {
            if (this.currentUrl.includes(`/report`)) {
              this.updateLocation(`/report/${this.associatedFiscalYear + 1}`);
            }

            this.authStore$.dispatch(
              switchUserAssociatedFiscalYearAction({ fiscalYear: this.associatedFiscalYear + 1 }),
            );
          }

          localStorage.setItem(`switch-fiscal-year-dialog`, `true`);
        }),
      )
      .subscribe();
  }

  updateLocation(locationUrl: string): void {
    this.location.go(locationUrl);

    this.sharedStore$.dispatch(
      pushTagAction({
        tag: {
          event: `page_view`,
        },
      }),
    );
  }

  openAccountsDialog(): void {
    this.accountsDialogOpened = true;

    this.changeDetectorRef.detach(); // Performance optimization for large number of accounts

    const dialogRef = this.utilsService.openDialog(AccountsDialogComponent, `722px`, `72vh`, {
      panelClass: [`custom-overlay-accounts`],
      selectedAccount: this.urlAccount,
    });

    if (!this.currentUrl.includes(`/add`)) this.updateLocation(this.location.path().concat(`/add`));

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        map(() => {
          this.updateLocation(`/accounts`);
          this.accountsDialogOpened = false;
          this.urlAccount = ``;

          this.changeDetectorRef.reattach(); // Performance optimization for large number of accounts

          this.accountStore$.dispatch(loadUserAccountsAction());
        }),
      )
      .subscribe();
  }
}
