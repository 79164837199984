import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CustomFocusDirective } from '../../../shared/directives/custom-focus.directive';
import { File, TimezoneDetails } from '../../../shared/models/file.model';
import { Account } from '../../models/account.model';
import { Upload } from '../../models/upload.model';
import { UserAccount } from '../../models/user-account.model';
import { ToastService } from '../../../shared/services/toast.service';

type FILES_STATUS = `LOADING` | `ERROR` | `SUCCESS`;

@Component({
  selector: `app-file-importer`,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    TranslateModule,
    DropzoneModule,
    MatProgressBarModule,
    CustomFocusDirective,
  ],
  templateUrl: `./file-importer.component.html`,
  styleUrls: [`./file-importer.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileImporterComponent implements OnInit, OnDestroy {
  @Input() set account(account: Account) {
    this._account = account;
    this.updateUploadUrl();
    this.updateAcceptedFileExtensions();
  }

  get account(): Account {
    return this._account;
  }

  @Input() set accessToken(accessToken: string) {
    this._accessToken = accessToken;

    this.dropzoneConfig.headers = {
      Authorization: `Bearer ${accessToken}`,
      waltio_user: sessionStorage.getItem(`waltio_user`),
    };
  }

  get accessToken(): string {
    return this._accessToken;
  }

  @Input() subAccount: Account;
  @Input() timezones: TimezoneDetails[] = [];
  @Input() userAccounts: UserAccount[] = [];
  @Input() set autoProcessQueue(autoProcessQueue: boolean) {
    if (autoProcessQueue === undefined) {
      this._autoProcessQueue = true;
    } else {
      this._autoProcessQueue = autoProcessQueue;
    }

    this.dropzoneConfig.autoProcessQueue = this._autoProcessQueue;
  }

  get autoProcessQueue(): boolean {
    return this._autoProcessQueue;
  }

  @Output() uploadSuccess: EventEmitter<void> = new EventEmitter<void>();
  @Output() uploadError: EventEmitter<string> = new EventEmitter<string>();
  @Output() filesAdded: EventEmitter<FileList> = new EventEmitter<FileList>();

  private _account: Account;
  private _accessToken: string;
  private _autoProcessQueue: boolean;

  timezoneControl: FormControl = new FormControl(null);
  uploadUrl = environment.apiUrl + `/v1/tax/file/upload/multi/`;

  dropzoneConfig: DropzoneConfigInterface = {
    url: this.uploadUrl,
    clickable: `#dropzone-elements`,
    uploadMultiple: true,
    paramName: () => `file`,
    maxFiles: 100,
    parallelUploads: 100,
  };

  filesStatus = ``;
  filesProgress = 0;
  files: File[] = [];
  uploadedFiles: File[] = [];
  canceledFiles: File[] = [];
  fileOnUserAccounts = false;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.timezoneControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        map((timezone: string) => {
          if (timezone) {
            this.updateUploadUrl();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isOnUserAccounts(file: any): boolean {
    const matchedAccount = this.userAccounts
      .filter((account) => account.type === `FILE`)
      .find((account) => account.name === file.name);
    return matchedAccount !== undefined;
  }

  updateUploadUrl(): void {
    const timezone = this.timezoneControl.value || ``;
    this.dropzoneConfig.autoProcessQueue = this.autoProcessQueue;

    this.dropzoneConfig.url = `${this.uploadUrl}${(this.subAccount || this.account).key}?timezone=${timezone}`;

    if (this.subAccount) {
      this.dropzoneConfig.url = this.dropzoneConfig.url.concat(`&aggregator=${this.account.key}`);
    }
  }

  onAddedFiles(files: any): void {
    this.files = [];
    this.uploadedFiles = [];
    this.canceledFiles = [];
    this.filesProgress = 0;
    this.timezoneControl.disable();

    for (const file of files) {
      const index = this.files.findIndex((f: File) => f.data.name === file.name);
      const extension = file.name.split(`.`).slice(-1)[0];

      if (!this.dropzoneConfig.acceptedFiles.includes(extension)) {
        const translation = this.translateService.instant(`FileNotAcceptedMsg`);
        const errorMessage = `${extension} : ${translation}`;
        this.canceledFiles.push({ data: file, errorMessage });
        this.uploadError.emit(errorMessage);
      }

      if (index === -1) {
        this.files.push({ data: file, progress: 0 });
      }
    }

    if (this.autoProcessQueue) {
      this.filesStatus = `LOADING`;
    } else {
      this.filesStatus = `ADDED`;
      this.filesAdded.emit(files);
    }

    this.calculateFilesProgress();
  }

  onUploadSuccess(e: any): void {
    const file = e[0];
    const uploadResponse: Upload = e[1][0];

    let errorMessage = `${file.name} :`;
    if (!uploadResponse || e[1].length === 0) {
      const alreadyAddedFile = this.translateService.instant(`ALREADY_ADDED_FILE`);
      errorMessage += ` ${alreadyAddedFile}`;
      this.canceledFiles.push({ data: file, errorMessage });
      this.uploadError.emit(errorMessage);
    } else if (uploadResponse.status === `UNRECOGNIZED` && uploadResponse.filename === file.name) {
      const unrecognizedFile: string = this.translateService.instant(`UNRECOGNIZED_FILE`);
      errorMessage += ` ${unrecognizedFile}`;
      this.canceledFiles.push({ data: file, errorMessage });
      this.uploadError.emit(errorMessage);
    } else {
      this.uploadedFiles.push({ data: file });
    }

    this.updateFile(file);
  }

  onUploadError(e: any): void {
    this.timezoneControl.enable();
    const file = e[0];

    const index = this.files.findIndex((f: File) => f.data.name === file.name);
    if (index === -1) {
      this.files.push({ data: file, progress: 0 });
    }

    // eslint-disable-next-line quotes
    let errorMessage: string | null = `${file.name} :`;

    const response = e[1];
    if (response?.status === 500 || response?.status === 400) {
      if (response.message) {
        errorMessage += ` ${response.message}`;
      } else {
        // eslint-disable-next-line quotes
        errorMessage += ` ${this.translateService.instant('UNKNOWN_ERROR')}`;
      }
    } else {
      errorMessage += ` ${e[1]}`;
    }

    if (!this.isOnUserAccounts(file)) {
      errorMessage = null;
    }

    this.canceledFiles.push({ data: file, errorMessage });
    this.uploadError.emit(errorMessage);
    this.updateFile(file);
  }

  updateFile(file: any): void {
    const index = this.files.findIndex((f: File) => f.data.name === file.name);

    if (index >= 0) {
      this.files[index].data = file;
      this.files[index].progress = 100;
    }

    this.calculateFilesProgress();
  }

  calculateFilesProgress(): void {
    this.filesProgress = ((this.canceledFiles.length + this.uploadedFiles.length) / this.files.length) * 100;

    if (this.filesProgress === 100) {
      this.checkFilesStatus();
    }
  }

  checkFilesStatus(): void {
    if (this.canceledFiles.length > 0) {
      this.filesStatus = `ERROR`;
    }

    if (this.uploadedFiles.length > 0) {
      if (this.canceledFiles.length === 0) {
        const message = ``
          .concat(this.translateService.instant(`TOAST.ACCOUNT`))
          .concat(` "${this.subAccount?.name || this.account?.name}" `)
          .concat(this.translateService.instant(`TOAST.ADDED`));

        this.toastService.success(message);

        this.uploadSuccess.emit();
      } else {
        this.uploadedFiles.forEach((file: File) => {
          const message = ``
            .concat(this.translateService.instant(`FILE`))
            .concat(` "${file.data.name}" `)
            .concat(this.translateService.instant(`ADDED`));
          this.toastService.success(message);
        });
      }
    }
  }

  updateAcceptedFileExtensions(): void {
    const extensions: string[] = Object.keys(this.account.supportedFileTypes)
      .filter((key: string) => this.account.supportedFileTypes[key])
      .map((key: string) => `.${key}`);

    this.dropzoneConfig.acceptedFiles = extensions.join(`, `);
  }
}
