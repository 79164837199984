import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { TokenSwapperComponent } from '../../../portfolio-manager/components/token-swapper/token-swapper.component';
import { MarketFeed } from '../../../portfolio-manager/models/market.model';
import { TickerFeed } from '../../../portfolio-manager/models/ticker.model';
import * as fromInsight from '../../../portfolio-manager/store/selectors/insight.selector';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { TransactionsBreakdownTooltipComponent } from '../../../taxation/components/transactions-breakdown-tooltip/transactions-breakdown-tooltip.component';
import { Account } from '../../../taxation/models/account.model';
import { SubscriptionNextPayment } from '../../../taxation/models/subscription.model';
import { User, UserAddon, UserPlan } from '../../../taxation/models/user.model';
import { LegalFormsPageComponent } from '../../../taxation/pages/legal-forms-page/legal-forms-page.component';
import {
  loadAvailableAddonsAction,
  loadAvailablePlansAction,
  loadPlansByFiscalYearsAction,
  loadSubscriptionNextPaymentAction,
  loadUpgradeFishingEstimateAction,
} from '../../../taxation/store/actions/payment.action';
import * as fromAssessment from '../../../taxation/store/selectors/assessment.selector';
import * as fromPayment from '../../../taxation/store/selectors/payment.selector';
import * as fromAccount from '../../../taxation/store/selectors/account.selector';
import { Feature } from '../../models/feature.model';
import { SidenavComponentType } from '../../models/sidenav.model';
import { UserPreferences } from '../../models/user-preferences.model';
import { FeatureService } from '../../services/feature.service';
import { SidenavService } from '../../services/sidenav.service';
import { UtilsService } from '../../services/utils.service';
import { BetaProgramDialogComponent } from '../dialogs/beta-program-dialog/beta-program-dialog.component';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { CommonModule } from '@angular/common';
import { loadUserAccountsAction } from '../../../taxation/store/actions/account.action';
import { UpgradeFishingDialogComponent } from '../../../taxation/components/dialogs/upgrade-fishing-dialog/upgrade-fishing-dialog.component';
import { PaymentEstimateV3 } from '../../../taxation/models/payment.model';

@Component({
  selector: `app-layout`,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatDividerModule,
    TransactionsBreakdownTooltipComponent,
    SidenavComponent,
    LegalFormsPageComponent,
    TokenSwapperComponent,
  ],
  templateUrl: `./layout.component.html`,
  styleUrls: [`./layout.component.scss`],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(`rightSidenav`) rightSidenav: MatSidenav;

  accounts: Map<string, Account>;

  stablecoins: string[];
  fiats: string[];
  userPreferences: UserPreferences;

  marketFeed: MarketFeed;
  tickerFeed: TickerFeed;

  hasPaid = false;

  upgradeFishingEstimate: PaymentEstimateV3;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly paymentStore$: Store<fromPayment.State>,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly assessmentStore$: Store<fromAssessment.State>,
    private readonly insightStore$: Store<fromInsight.State>,
    private readonly accountStore$: Store<fromAccount.State>,
    private readonly sidenavService: SidenavService,
    private readonly utilsService: UtilsService,
    private readonly featureService: FeatureService,
  ) {}

  ngOnInit(): void {
    this.accountStore$.dispatch(loadUserAccountsAction());
    this.paymentStore$.dispatch(loadSubscriptionNextPaymentAction());
    this.paymentStore$.dispatch(loadAvailablePlansAction());
    this.paymentStore$.dispatch(loadAvailableAddonsAction());
    this.paymentStore$.dispatch(loadPlansByFiscalYearsAction());

    this.assessmentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAssessment.selectCurrentFiscalYear),
        map((fiscalYear: number) => {
          this.paymentStore$.dispatch(
            loadUpgradeFishingEstimateAction({
              fiscalYear,
              checkoutType: `PLAN`,
              computeAssessmentStatus: true,
            }),
          );
        }),
      )
      .subscribe();

    combineLatest([
      this.authStore$.pipe(select(fromAuth.selectUser)),
      this.paymentStore$.pipe(select(fromPayment.selectSubscriptionNextPayment)),
      this.sharedStore$.pipe(select(fromShared.selectFeatures(`lab`))),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([user, subscriptionNextPayment, features]: [User, SubscriptionNextPayment, Feature[]]) => {
          if (user && subscriptionNextPayment && features?.length === 1) {
            this.hasPaid = user.plan !== `PLN000` && user.plan !== `PLN300`;

            this.paymentStore$.dispatch(
              loadUpgradeFishingEstimateAction({
                fiscalYear: user.associatedFiscalYear,
                checkoutType: `PLAN`,
                computeAssessmentStatus: true,
              }),
            );

            const userPlan = (subscriptionNextPayment.plans.find(
              (sub: UserPlan | UserAddon) => sub !== `AOCARE001` && sub !== `AOCARE002`,
            ) || `PLN000`) as UserPlan;
            const betaProgram = localStorage.getItem(`beta-program`);
            const openDialog =
              this.featureService.isRangedFeatureEnabled(features[0]) &&
              betaProgram === null &&
              user.fiscalResidency === `FR` &&
              userPlan !== `PLN000`;

            if (openDialog) {
              this.openBetaProgramDialog();
            }
          }
        }),
      )
      .subscribe();

    combineLatest([
      this.sharedStore$.select(fromShared.selectStableCoins),
      this.sharedStore$.select(fromShared.selectFiats),
      this.sharedStore$.select(fromShared.selectUserPreferences),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([stablecoins, fiats, userPreferences]) => {
          this.stablecoins = stablecoins;
          this.fiats = fiats;
          this.userPreferences = userPreferences;
        }),
      )
      .subscribe();

    combineLatest([
      this.insightStore$.select(fromInsight.selectMarketFeed),
      this.insightStore$.select(fromInsight.selectTickerFeed),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([marketFeed, tickerFeed]: [MarketFeed, TickerFeed]) => {
          this.marketFeed = marketFeed;
          this.tickerFeed = tickerFeed;
        }),
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectAllAccounts),
        map((accounts: Map<string, Account>) => (this.accounts = accounts)),
      )
      .subscribe();

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectUpgradeFishingEstimate),
        map((upgradeFishingEstimate: PaymentEstimateV3) => {
          this.upgradeFishingEstimate = upgradeFishingEstimate;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.rightSidenav);
    this.sidenavService.setSidenavComponentType(`LegalForm`);
  }

  getSidenavComponentType(): SidenavComponentType {
    return this.sidenavService.getSidenavComponentType();
  }

  closeSidenav(): void {
    this.sidenavService.close();
  }

  openBetaProgramDialog(): void {
    setTimeout(() => {
      const dialogRef = this.utilsService.openDialog(BetaProgramDialogComponent, `587px`, `auto`);

      dialogRef
        .afterClosed()
        .pipe(
          takeUntil(this.destroy$),
          map((res: boolean) => {
            if (!res) {
              localStorage.setItem(`beta-program`, `false`);
            }
          }),
        )
        .subscribe();
    }, 10000);
  }

  upgradeFishing(): void {
    this.utilsService.openDialog(UpgradeFishingDialogComponent, `400px`, `auto`, {
      name: `access-to-ph3`,
      checkoutType: `PLAN`,
      title: `OPTIMIZE_PASSIVE_RETURNS`,
      offer: `PLN500`,
      fiscalYear: this.upgradeFishingEstimate.fiscalYear,
      updateTitle: false,
    });
  }
}
