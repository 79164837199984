import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Feature, IzanamiResponse } from '../models/feature.model';
import moment from 'moment';
import { map } from 'rxjs/operators';
import md5 from 'md5';

@Injectable({
  providedIn: `root`,
})
export class FeatureService {
  constructor(private readonly http: HttpClient) {}

  /**
   *  Get all features from Izanami
   *
   * @param page
   * @param pageSize
   * @param pattern
   * @returns
   */
  getFeatures(page = 1, pageSize = 9999, pattern = `*`): Observable<IzanamiResponse> {
    return this.http.get<IzanamiResponse>(
      `${environment.apiUrl}/v1/tax/app/features?page=${page}&pageSize=${pageSize}&pattern=${environment.features.pattern}${pattern}`,
    );
  }

  isRangedFeatureEnabled(feature: Feature): boolean {
    const startDate = moment(feature.parameters.from);
    const endDate = moment(feature.parameters.to);
    const today = moment();

    const startDateWithoutYear = moment({ month: startDate.month(), date: startDate.date() });
    const endDateWithoutYear = moment({ month: endDate.month(), date: endDate.date() });

    return feature.enabled && today.isBetween(startDateWithoutYear, endDateWithoutYear, `day`, `[]`);
  }

  isCustomersListFeatureEnabled(feature: Feature, email: string): Observable<boolean> {
    if (feature?.parameters?.customers?.length === 0 || !feature?.parameters?.customers) {
      return of(feature.enabled);
    } else {
      return this.http.get<string[]>(feature.parameters?.customers[0]).pipe(
        map((customers: string[]) => {
          return feature.enabled && (customers.includes(md5(email)) || customers.includes(email));
        }),
      );
    }
  }

  isReleaseDateFeatureEnabled(feature: Feature, isBefore = false): boolean {
    // Get day and month and ignore year
    const releaseDate = moment(feature.parameters.releaseDate);
    const releaseDateWithoutYear = moment({ month: releaseDate.month(), date: releaseDate.date() });
    const today = moment();
    const todayWithoutYear = moment({ month: today.month(), date: today.date() });

    if (isBefore) {
      return feature.enabled && todayWithoutYear.isBefore(releaseDateWithoutYear);
    } else {
      return feature.enabled && todayWithoutYear.isAfter(releaseDateWithoutYear);
    }
  }
}
